<template>
  <div>
    <h3 class="my-1">{{ $t('onboarding_step_2_title') }}</h3>
    <b-card>
      <div id="sumsub-websdk-container"></div>
    </b-card>
  </div>
</template>

<script>

  import snsWebSdk from '@sumsub/websdk';

  export default {
    data() {
      return {
        sumsubToken: null,
        sumsubUserId: null,
        sumsub_id: null,
      }
    },

    created() {
      this.completeIdentification();
    },

    methods: {
      completeIdentification() {
        this.choiceConfirmed = true;
        this.showLoader = true;
        this.levelName = 'seller'
        this.$http.post(`/buyer/getSumsubClientId`, {role: this.levelName}).then((res) => {
          if (res.data) {
            this.sumsubUserId = res.data.value.clientId;
            setTimeout(() => {
              this.$http.post(`/sumsub/token?user_id=${this.$store.state.auth.user.id}&sumsub_id=${this.sumsubUserId}&level=${this.levelName}`).then((res) => {
                if (res.data) {

                  this.sumsubToken = res.data.token;
                  console.log(this.sumsubToken)
                  // this.launchWebSdk(this.sumsubToken, this.$store.state.auth.user.email, this.$store.state.auth.user.tel);
                  this.launchWebSdk();
                }
              });
            }, 2000);
          }
        });
      },

      launchWebSdk(accessToken, applicantEmail, applicantPhone) {
        accessToken = this.sumsubToken;
        let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            () => this.getNewAccessToken()
        )
            .withConf({
              lang: 'en',
              email: applicantEmail,
              phone: applicantPhone,
              // externalUserId: `Prod-${this.$store.state.auth.user.id}`,
              // id: this.sumsubUserId
            })
            .withOptions({addViewportTag: false, adaptIframeHeight: true})
            .on('idCheck.onStepCompleted', (payload) => {
              console.log('onStepCompleted', payload)
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error)
            })
            .build();
        snsWebSdkInstance.launch('#sumsub-websdk-container');
        this.showLoader = false;
      },

    }

  }
</script>

<style scoped>

</style>